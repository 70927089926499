import "./Ordering.css"
import IconLabel from "../components/IconLabel"
import WarningIcon from "../img/warning.svg"

function OrderBox({ header, steps = [], footer }) {
  const orderStepsLi = steps.map((str) => {
    return <li>{str + "."}</li>
  })

  return <div className="OrderBox HoverScaleMinus">
    <div className="OrderBoxText">{header}</div>
    <ul>{orderStepsLi}</ul>
    <div className="OrderBoxText">{footer}</div>
  </div>
}

function OrderSteps() {
  const orderBoxArgs1 = {
    header: "Для оформления автомобиля на стоянку требуется:",
    footer: "Оплата производится за наличный расчет. Выдается квитанция об оплате",
    steps: [
      "свидетельство о регистрации транспортного средства"
    ]
  }

  const orderBoxArgs2 = {
    header: "При получении автомобиля необходимо предъявить:",
    footer: "При необходимости потребуется оплатить образовавшуюся задолженность",
    steps: [
      "свидетельство о регистрации транспортного средства",
      "квитанцию об оплате"
    ]
  }

  return (
    <div className="OrderingInfo_OrderSteps">
      <OrderBox {...orderBoxArgs1} />
      <OrderBox {...orderBoxArgs2} />
    </div>
  )
}

export default function OrderingSection() {
  return (
    <>
      <div id="ordering" className="ColorBlock SubpageStart">ОФОРМЛЕНИЕ АВТОМОБИЛЯ</div>
      <div className="CenteredBox Container OrderingSection">
        <div className="OrderingInfo_DescTop">Автостоянка представляет собой огороженную, охраняемую, оборудованную видеонаблюдением парковку. Вы можете оставить свое авто от нескольких часов до нескольких месяцев.</div>
        <div className="ColorBlock OrderingInfo_DescTop_Capacity HoverScalePlus">
          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>250</span>
          машиномест
        </div>

        <OrderSteps />
        <div className="OrderingInfo_DescBottom">Время исчисления услуг парковки начинается с момента постановки транспортного средства. Стоимость услуг стоянки за 1 сутки (24 часа) - 200 рублей.</div>
        <div className="OrderingInfo_IconLabel">
          <IconLabel icon={WarningIcon} text="В случае опоздания клиента более чем на 2 часа, взимается плата за последующие сутки!" />
        </div>
      </div>
    </>
  )
}