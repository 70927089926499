import "./Navbar.css";

function NavbarButton({ name, anchorName }) {
  return (
    <a href={'#' + anchorName}>
      <button className="NavbarButton ButtonTextHover">{name}</button>
    </a>
  )
}

export default function Navbar() {
  return (
    <div className="Navbar ColorBlock">
      <NavbarButton anchorName="billorder" name="ЦЕНА" />
      <NavbarButton anchorName="gallery" name="ГАЛЕРЕЯ" />
      <NavbarButton anchorName="advantages" name="ПРЕИМУЩЕСТВА" />
      <NavbarButton anchorName="ordering" name="ОФОРМЛЕНИЕ" />
      <NavbarButton anchorName="howtoreach" name="КАК ДОБРАТЬСЯ" />
      {/* <NavbarButton anchorName="faq" name="ВОПРОСЫ/ОТВЕТЫ" /> */}
      <NavbarButton anchorName="contacts" name="КОНТАКТЫ" />
    </div>
  )
}
