import "./IconLabel.css"

// export default function IconLabel({ icon, text }) {
//   return (
//     <>
//       <button className="IconLabel ColorBlock">
//         <img loading="lazy"  src={icon} className="IconLabel_Icon"></img>
//         {text}
//       </button>
//     </>
//   )
// }

export default function IconLabel({ icon, text }) {
  return (
    <div className="ColorBlock IconLabel HoverScalePlus">
      <img src={icon} alt="" className="IconLabel_Icon"></img>
      {text}
    </div>
  )
}