import "./Advantages.css"
import Advantage from "../components/Advantage"
import AllTimeOpenIcon from "../img/24hours.svg"
import CCTVIcon from "../img/cctv.svg"
import SecurityIcon from "../img/security.svg"
import PlaneIcon from "../img/plane.svg"

export default function AdvantagesSection() {
  return (
    <>
      <div id="advantages" className="ColorBlock SubpageStart">
        ПРЕИМУЩЕСТВА
      </div>
      <div className="CenteredBox Container AdvantagesSection">
        <Advantage icon={AllTimeOpenIcon} header="Круглосуточный режим работы" text="Режим работы нашей автостоянки - 7 дней в неделю без обеда и выходных." />
        <Advantage icon={CCTVIcon} header="Видеонаблюдение" text="По всему периметру автостоянки установлены камеры видеонаблюдения." />
        <Advantage icon={SecurityIcon} header="Кругосуточная охрана" text="Работники осуществляют обход территории. Парковка освещена и находится под постоянным видеонаблюдением." />

        {/* <Advantage icon={RestIcon} header="Комната отдыха, туалет" text="Комната отдыха, где можно привести себя в порядок, погреться и попить чай." /> */}
        <Advantage icon={PlaneIcon} header="Близость аэропорта" text="Cтоянка расположена непосредственно у дороги в аэропорт Кольцово" />
      </div>
    </>
  )
}