import "./Footer.css"
import { isDesktop } from 'react-device-detect';

const phoneNumber = "+79676306554"

export default function Footer() {
  const phoneObj =
    isDesktop ?
      <span>{phoneNumber}</span> :
      <a href={"tel:" + phoneNumber}>{phoneNumber}</a>

  return (
    <footer>
      <div id="contacts" style={{ height: "1em" }} className="ColorBlock" />
      <div className="FooterBlock CenteredBlock">
        <div className="FooterContacts">
          <span>КОНТАКТЫ:</span>

          <span>АДРЕС:</span>
          <span>г. Екатеринбург, ул. Спутников, 11А</span>

          <span>РЕЖИМ РАБОТЫ:</span>
          <span>Круглосуточно</span>

          <span>Звоните нам!</span>
          {phoneObj}
        </div>
      </div>
    </footer>
  )
}