import "./Contacts.css"
import MapPointIcon from "../../img/map_point_white.svg"
import PhoneIcon from "../../img/phone_white.svg"
import { isDesktop } from 'react-device-detect';

function PhoneNumber({ number }) {
  const numberObj =
    isDesktop ?
      number :
      <a style={{ textDecoration: "none", color: "white" }} href={"tel:" + number}>{number}</a>

  return (
    <div className="ContactInfoColumn PhoneNumber">
      <img src={PhoneIcon} alt="" className="ContactIcon" />
      {numberObj}

    </div>
  )
}

function MapAddress({ address }) {
  return (
    <div className="ContactInfoColumn MapAddress">
      <img src={MapPointIcon} alt="" className="ContactIcon" />
      {address}
    </div>
  )
}

export default function ContactInfo() {
  return (
    <div className="ColorBlock">
      <div className="ContactInfo CenteredBox">
        <MapAddress className="MapAddress" address="ул. Спутников, 11А" />
        <PhoneNumber number="+79676306554" />
      </div>
    </div>
  )
}