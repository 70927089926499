import "./HowToReach.css"
import BlueMapPointIcon from "../img/map_point_blue.svg"
import PrintIcon from "../img/print.svg"
import TimeIcon from "../img/time.svg"
import PhoneIcon from "../img/phone_blue.svg"
import PathIcon from "../img/path.svg"
import GoogleLogoIcon from "../img/google_logo.svg"
import YandexMapsIcon from "../img/yandex_maps.svg"
import IconLabel from "../components/IconLabel"
import MapToPrintPhoto from '../img/map_to_print.png'

function IconRow({ icon, header, text }) {
  return (
    <div className="IconRow">
      <div className="IconRowText">
        <div className="IconRowHeader">
          <img src={icon} alt="" className="IconRowIcon"></img>
          {header}
        </div>
        {text}
      </div>
    </div>
  )
}

function URLButton({ text, icon, url }) {
  return (
    <a className="HowToReach_URL" href={url}>
      <IconLabel icon={icon} text={text} />
    </a>
  )
}

export default function HowToReachSection() {
  const iconRows = [
    {
      header: "Как проехать на стоянку в Кольцово",
      text: `Адрес для GPS: Свердловская область, г. Екатеринбург, мкр. Кольцово, 
ул. Спутников, 11А

Широта: 56°45′28″N (56.757781)
Долгота: 60°48′30″E (60.808462)`,
      icon: PathIcon
    },

    {
      header: "Адрес для Яндекс- и Google-карт",
      text: "В поле для ввода адреса напишите: 56.757781, 60.808462",
      icon: BlueMapPointIcon
    },

    {
      header: "Время в пути",
      text: "От автостоянки до аэропорта Кольцово 7 минут на машине и 15 минут пешком",
      icon: TimeIcon
    },

    {
      header: "Номер телефона: +7 967 630 65 54",
      icon: PhoneIcon
    },
  ]

  const iconRowObjects = iconRows.map((iconRowCfg, i) => <IconRow {...iconRowCfg} />)

  return (
    <>
      <div id="howtoreach" className="ColorBlock SubpageStart">КАК ДОБРАТЬСЯ</div>
      <div className="CenteredBox Container HowToReach">
        <div>
          {iconRowObjects}
        </div>
        <div className="HowToReach_Icons">
          <URLButton url={MapToPrintPhoto} icon={PrintIcon} text="Фото карты" />
          <br />
          <URLButton url="https://yandex.ru/maps/-/CDe4VQ9l" icon={YandexMapsIcon} text="Мы в Яндекс" />
          <br />
          <URLButton url="https://maps.app.goo.gl/NANDWLwK67DgT44K8" icon={GoogleLogoIcon} text="Мы в Google" />
        </div>
      </div>
    </>
  )
}