import "./Header.css"
import ContactInfo from "./Contacts"

export default function Header() {
  return (
    <>
      <div className="Header ColorBlock">
        ПАРКОВКА В КОЛЬЦОВО
        <div className="SubHeader">
          Бюджетные парковочные места в 15 минутах от аэропорта
        </div>
      </div>
      <ContactInfo />
    </>
  )
}
