import "./Advantage.css"

export default function Advantage({ icon, header, text }) {
  return (
    <div className="Advantage HoverScaleMinus">
      <img className="AdvantageIcon" alt="" src={icon} />
      <span className="AdvantageHeader">{header}</span>
      {text}
    </div>
  )
}