import Navbar from "./components/Navbar";
import Header from "./components/header/Header";
import YMap from "./sections/Map";
import BillOrder from "./sections/BillOrder";
import GallerySection from "./sections/Gallery";
import AdvantagesSection from "./sections/Advantages";
import OrderingSection from "./sections/Ordering";
import HowToReachSection from "./sections/HowToReach";
// import FAQSection from "./sections/FAQ"
import Footer from "./sections/Footer";

export default function App() {
  return (
    <div className="App">
      <Header />
      <YMap />
      <Navbar />
      <BillOrder />
      <GallerySection />
      <AdvantagesSection />
      <OrderingSection />
      <HowToReachSection />
      {/* <FAQSection /> */}
      <Footer />
    </div>
  );
}
