import { useRef, useState } from "react"
import "./Gallery.css"
import LeftArrowIcon from "../img/arrow_left.svg"
import RightArrowIcon from "../img/arrow_right.svg"

import { isMobile } from 'react-device-detect'

import Image1 from '../img/gallery/1.jpg'
import Image1Min from '../img/gallery/1min.jpg'

import Image2 from '../img/gallery/2.jpg'
import Image2Min from '../img/gallery/2min.jpg'

import Image3 from '../img/gallery/3.jpg'
import Image3Min from '../img/gallery/3min.jpg'

import Image4 from '../img/gallery/4.jpg'
import Image4Min from '../img/gallery/4min.jpg'

import Image5 from '../img/gallery/5.jpg'
import Image5Min from '../img/gallery/5min.jpg'

const imageURLs = [
  {
    desktop: Image1,
    mobile: Image1Min,
  },

  {
    desktop: Image2,
    mobile: Image2Min,
  },

  {
    desktop: Image3,
    mobile: Image3Min,
  },

  {
    desktop: Image4,
    mobile: Image4Min,
  },

  {
    desktop: Image5,
    mobile: Image5Min,
  },
]

const getImage = (idx) => imageURLs[idx][isMobile ? 'mobile' : 'desktop']
const imageLastIdx = imageURLs.length - 1

function Gallery() {
  const imgRef = useRef(null)
  const [activeButtonIdx, setActiveButtonIdx] = useState(0)

  function scrollToIndex(i) {
    const ul = imgRef.current;

    const img = ul.querySelectorAll('li > img')[i];
    img.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });

    setActiveButtonIdx(i)
  }

  const navButtons = imageURLs.map(
    (_, i) => {
      const style = {
        transform: "scale(" + (activeButtonIdx === i ? "1.3" : "1") + ")"
      }
      return <button style={style} className="GalleryNavButton" onClick={() => scrollToIndex(i)} />
    }
  )

  function navigate(addAmount) {
    let newIdx = activeButtonIdx + addAmount

    if (newIdx < 0) {
      newIdx = imageLastIdx
    } else if (newIdx > imageLastIdx) {
      newIdx = 0
    }

    scrollToIndex(newIdx)
  }

  const images = imageURLs.map((_, idx) =>
    <li className="GalleryLi" onClick={() => navigate(1)}>
      <img className="GalleryLiImage" src={getImage(idx)} alt={"Фото " + idx}>
      </img>
    </li>
  )

  function NavButton({ icon, addAmount }) {
    return (
      <button onClick={() => navigate(addAmount)} className="GalleryRowArrowButton">
        <img src={icon} alt=""></img>
      </button>
    )
  }

  return (
    <>
      <div className="GalleryRows">
        <NavButton icon={LeftArrowIcon} addAmount={-1} />
        <ul className="GalleryRowsSlideshow" ref={imgRef}>
          {images}
        </ul>
        <NavButton icon={RightArrowIcon} addAmount={1} />
      </div>
      <div className="GalleryNavButtons">{navButtons}</div>
    </>
  )
}

function GalleryMobile() {
  const imgRef = useRef(null)
  const [activeImageIdx, setActiveImageIdx] = useState(0)

  function setCurImage(i) {
    const img = imgRef.current;
    img.src = getImage(i)
    setActiveImageIdx(i)
  }

  function navigate(addAmount) {
    let newIdx = activeImageIdx + addAmount
    if (newIdx < 0) {
      newIdx = imageLastIdx
    } else if (newIdx > imageLastIdx) {
      newIdx = 0
    }
    setCurImage(newIdx)
  }

  function NavButton({ addAmount, isRotate }) {
    let className = isRotate ? "MobileGalleryRotate" : ""
    className += " GalleryRowArrowButton"

    return (
      <button onClick={() => navigate(addAmount)} className={className}>
        <img src={LeftArrowIcon} alt=""></img>
      </button>
    )
  }

  return (
    <>
      <div className="GalleryRowsSlideshow">
        <img ref={imgRef} className="GalleryLiImage" alt="" src={getImage(activeImageIdx)}></img>
      </div>
      <div className="MobileGalleryNavButtons">
        <NavButton addAmount={-1} />
        <NavButton isRotate={true} addAmount={1} />
      </div>
    </>
  )
}

export default function GallerySection() {
  const gallery = isMobile ? <GalleryMobile /> : <Gallery />
  return (
    <>
      <div id="gallery" className="ColorBlock SubpageStart">
        ГАЛЕРЕЯ
      </div>
      <div className="CenteredBox Container">
        {gallery}
      </div>
    </>
  )
}