import "./BillOrder.css"
import IconLabel from "../components/IconLabel"
import RoubleIcon from "../img/rouble.svg"
import CarSideIcon from "../img/car_side.svg"
import DocumentIcon from "../img/document.svg"

const order = [
  "Позвонить и узнать наличие свободных мест",
  "Приехать на стоянку",
  "Оплатить время и взять квитанцию"
]

function AdditionalInfo() {
  return (
    <div className="BillOrderAdditionalInfo">
      <IconLabel icon={RoubleIcon} text="Цена - 250р" />
      <IconLabel icon={CarSideIcon} text="250 машиномест" />
    </div>
  )
}

function Steps() {
  const orderElement = order.map((str) => {
    return <li>{str + "."}</li>
  })

  return (
    <div className="BillOrderSteps ListHeader">
      Порядок действий:
      <ol className="ListElement">{orderElement}</ol>
    </div>
  )
}

export default function BillOrder() {
  return (
    <div id="billorder" className="CenteredBox Container">
      <div className="BillOrderHorizontal">
        <Steps />
        <AdditionalInfo />
      </div>
      <IconLabel icon={DocumentIcon} text="Для получения автомобиля необходима квитанция!" />
    </div>
  )
}
